<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat rounded>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-dialog
                    ref="endDateDialog"
                    v-model="endDateDialog"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="endDateText"
                        prepend-icon="mdi-calendar"
                        readonly
                        label="Kontrol Tarihi"
                        hide-details
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click:append="
                        filters.endDates = []
                        getData()
                  "
                        append-icon="mdi-close-circle"
                        style="max-width: 300px"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="filters.endDates"
                        scrollable
                        range
                        locale="tr"
                        color="green lighten-1"
                        header-color="primary"
                        min="2023-07-01"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endDateDialog = false"> İPTAL</v-btn>
                      <v-btn text color="primary" @click="fixDates('endDate')"> ARA</v-btn>
                    </v-date-picker>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-dialog
                    ref="startDateDialog"
                    v-model="startDateDialog"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="startDateText"
                        prepend-icon="mdi-calendar"
                        readonly
                        label="Odev Verilme Tarihi"
                        hide-details
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click:append="
                        filters.startDates = []
                        getData()
                  "
                        append-icon="mdi-close-circle"
                        style="max-width: 300px"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="filters.startDates"
                        scrollable
                        range
                        locale="tr"
                        color="green lighten-1"
                        header-color="primary"
                        min="2023-07-01"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startDateDialog = false"> İPTAL</v-btn>
                      <v-btn text color="primary" @click="fixDates('startDate')"> ARA</v-btn>
                    </v-date-picker>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-dialog
                    ref="planningDateDialog"
                    v-model="planningDateDialog"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="planningDateText"
                        prepend-icon="mdi-calendar"
                        readonly
                        label="Planlama Tarihi"
                        hide-details
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click:append="
                        filters.planningDates = []
                        getData()
                  "
                        append-icon="mdi-close-circle"
                        style="max-width: 300px"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="filters.planningDates"
                        scrollable
                        range
                        locale="tr"
                        color="green lighten-1"
                        header-color="primary"
                        min="2023-10-01"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="planningDateDialog = false"> İPTAL</v-btn>
                      <v-btn text color="primary" @click="fixDates('planningDate')"> ARA</v-btn>
                    </v-date-picker>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <c-lesson v-model="filters.lessonId" @change="getData"></c-lesson>
              </v-col>
              <v-col cols="6">
                <v-select v-model="filters.homeworkStatus" style="max-width: 200px" :items="homeworkStatuses" outlined dense label="Ödev Durumu" hide-details @change="getData"></v-select>
              </v-col>
              <v-col cols="12">
                <v-btn block color="primary" class="ml-2" :to="{name: 'calisma-planim'}">Çalışma Planım</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-data-table
                v-model="selectedHomeworks"
                :headers="headers"
                :items="rows"
                :search="filters.search"
                :options.sync="options"
                :server-items-length="totalRows"
                :loading="loading"
                multi-sort
                :sort-by="options.sortBy"
                :sort-desc="options.sortDesc"
                disable-pagination
                hide-default-footer
                item-key="HomeworkId"
                show-select
                mobile-breakpoint="0"
            >
              <template v-slot:[`item.EndDate`]="{ item }">
                {{ item.EndDate | formattedDate }}
              </template>
              <template v-slot:[`item.PlannedDate`]="{ item }">
                {{ item.PlannedDate | formattedDate }}
              </template>
              <template v-slot:[`item.DoneDate`]="{ item }">
                {{ item.DoneDate | formattedDate }}
              </template>
              <template v-slot:[`item.StartDate`]="{ item }">
                {{ item.StartDate | formattedDate }}
              </template>
              <template v-slot:[`item.CorrectCount`]="{ item }">
                {{ item.CorrectCount }} / {{ item.WrongCount }} / {{ item.EmptyCount }}
              </template>
              <template v-slot:[`item.Status`]="{ item }">
                <span v-if="item.Status" style="color: forestgreen">YAPTI</span>
                <span v-else style="color: red">YAPMADI</span>
              </template>
              <template v-slot:[`item.ClassStatus`]="{ item }">
                <v-progress-linear
                    rounded
                    :value="item.CompletedStudentCount > 0 ? item.CompletedStudentCount / item.StudentCount * 100 : 0"
                    :color="getColor(item)"
                    height="25"
                >
                  <strong>{{ item.CompletedStudentCount }}/{{ item.StudentCount }}</strong>
                </v-progress-linear>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-btn v-if="!item.IsLocked" icon small @click.stop="openHomeworkAnswerDialog(item)">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="mt-5">
            <v-row v-show="rows.length > 0">
              <v-col>
                <v-chip color="primary" label class="m-2" small>Toplam Test Sayısı: {{ rows.length }}</v-chip>
                <v-chip color="primary" label class="m-2" small>Toplam Soru Sayısı: {{ rows.reduce((a, b) => a + b.QuestionCount, 0) }}</v-chip>
                <v-chip v-if="selectedHomeworks.length > 0" color="success" label class="m-2" small>Seçilen Test Sayısı: {{ selectedHomeworks.length }}</v-chip>
                <v-chip v-if="selectedHomeworks.length > 0" color="success" label class="m-2" small>Seçilen Soru Sayısı: {{ selectedHomeworks.reduce((a, b) => a + b.QuestionCount, 0) }}</v-chip>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="selectedHomeworks.length > 0" flat rounded>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-chip-group
                    v-model="editedItem.PlannedDate"
                    active-class="deep-purple--text text--accent-4"
                    mandatory
                    show-arrows
                    style="max-width: 700px"
                >
                  <v-chip
                      v-for="date in dates"
                      :key="date"
                      :value="date"
                  >
                    {{ showDay(date) }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12">
                <c-date-picker v-model="editedItem.PlannedDate" hide-details :key="editedItem.PlannedDate" label="Planlanan Tarih"></c-date-picker>
              </v-col>
              <v-col cols="12">
                <v-btn block color="primary" text @click="save" :loading="saving" large>Çalışma Planıma Ekle</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="homeworkAnswerDialog" max-width="350" class="my-3">
      <v-card flat>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text>
          <odev-cevaplari v-if="homeworkAnswerDialog" :test-id="answerItem.SourceTestId" :hw-id="answerItem.HomeworkId"></odev-cevaplari>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn
          v-show="selectedHomeworks.length > 0"
          color="primary"
          bottom
          right
          fixed
          x-large
          class="mb-10"
          @click.stop="planDialog = !planDialog"
      >
        <v-chip color="success" label outlined text-color="white" class="mr-1"> {{ selectedHomeworks.length }} Test</v-chip>
        <v-chip color="success" label outlined text-color="white"> {{ selectedHomeworks.reduce((a, b) => a + b.QuestionCount, 0) }} Soru</v-chip>
        <v-icon class="ml-2" small>mdi-calendar</v-icon>
        <span class="ml-1" style="font-size: x-small"> Plana Ekle </span>
      </v-btn>
    </v-fab-transition>
    <v-dialog
        ref="menu"
        v-model="planDialog"
        :return-value.sync="editedItem.PlannedDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
        max-width="350"
    >
      <v-date-picker
          v-model="editedItem.PlannedDate"
          scrollable
          full-width
      >
        <v-btn
            text
            color="primary"
            @click="planDialog = false"
        >
          Vazgeç
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="save"
        >
          Çalışma Planıma Ekle
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import ApiService from "@/core/services/api.service";
import OdevCevaplari from "@/view/pages/homework/HomeworkAnswers.vue";

export default {
  components: {OdevCevaplari},
  data() {
    return {
      totalRows: 0,
      rows: [],
      loading: true,
      options: {sortBy: ['EndDate'], sortDesc: [false]},
      headers: [
        {text: 'Ders', value: 'LessonName'},
        {text: 'Kaynak', value: 'SourceName'},
        {text: 'Konu', value: 'SubjectName'},
        {text: 'Test', value: 'SourceTestName'},
        {text: 'Sayfa', value: 'PageNumbers'},
        {text: 'Soru Sayısı', value: 'QuestionCount'},
        {text: 'Ödev Verilme Tarihi', value: 'StartDate'},
        {text: 'Son Kontrol Tarihi', value: 'EndDate'},
        {text: 'Planlanan Tarihi', value: 'PlannedDate'},
        {text: 'Tamamlama Tarihi', value: 'DoneDate'},
        {text: 'D/Y/B', value: 'CorrectCount', width: 100},
        {text: 'Durum', value: 'Status'},
        {text: 'Sınıf Durumu', value: 'ClassStatus', sortable: false},
        {text: '', value: 'Actions', sortable: false}
      ],
      filters: {
        search: '',
        lessonId: null,
        planningDates: [],
        endDates: [new Date().toISOString().slice(0, 10), new Date().addDays(10).toISOString().slice(0, 10)],
        startDates: [],
        homeworkStatus: 0
      },
      homeworkStatuses: [
        {value: 0, text: 'Tüm Ödevler'},
        {value: 1, text: 'Yaptıklarım'},
        {value: 2, text: 'Yapmadıklarım'},
        {value: 3, text: 'Planlamadıklarım'}
      ],
      homeworkAnswerDialog: false,
      editedItem: {
        HomeworkIds: [],
        PlannedDate: null
      },
      answerItem: {
        HomeworkId: 0,
        SourceTestId: 0
      },
      saving: false,
      selectedHomeworks: [],
      endDateDialog: false,
      planningDateDialog: false,
      startDateDialog: false,
      planDialog: false

    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    dates() {
      //return dates between today and one week later
      let dates = []
      let date = moment()
      for (let i = 0; i < 7; i++) {
        dates.push(date.format('YYYY-MM-DD'))
        date.add(1, 'days')
      }
      return dates
    },

    endDateText() {
      if (this.filters.endDates.length == 0) {
        return ''
      } else {
        return moment(this.filters.endDates[0]).locale('tr').format('Do MMMM') + ' - ' +
            moment(this.filters.endDates[1]).locale('tr').format('Do MMMM')
      }
    },

    startDateText() {
      if (this.filters.startDates.length == 0) {
        return ''
      } else {
        return moment(this.filters.startDates[0]).locale('tr').format('Do MMMM') + ' - ' +
            moment(this.filters.startDates[1]).locale('tr').format('Do MMMM')
      }
    },

    planningDateText() {
      if (this.filters.planningDates.length == 0) {
        return ''
      } else {
        return moment(this.filters.planningDates[0]).locale('tr').format('Do MMMM') + ' - ' +
            moment(this.filters.planningDates[1]).locale('tr').format('Do MMMM')
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
    homeworkAnswerDialog(val) {
      if (!val) {
        this.getData()
        this.answerItem = {
          HomeworkId: 0,
          SourceTestId: 0
        }
      }
    }
  },
  methods: {
    getData() {
      this.loading = true

      var query = this.$route.query.testIds != null ?
          {
            SourceTestIds: this.$route.query.testIds.split(',')
          } :
          {
            HomeworkControlStartDate: this.filters.endDates[0],
            HomeworkControlEndDate: this.filters.endDates[1],
            HomeworkAssignStartDate: this.filters.startDates[0],
            HomeworkAssignEndDate: this.filters.startDates[1],
            PlaningStartDate: this.filters.planningDates[0],
            PlanningEndDate: this.filters.planningDates[1],
            LessonId: this.filters.lessonId,
            HomeworkStatus: this.filters.homeworkStatus
          }

      ApiService.setHeader()
      ApiService.post('api/Homework/student-homework-planing',
          {
            sortBy: this.options.sortBy,
            descending: this.options.sortDesc,
            page: 1,
            rowsPerPage: -1,
            query: query
          })
          .then((data) => {
            this.rows = data.data.Results
            this.totalRows = data.data.TotalNumberOfRecords
            this.cleanSelections()
            this.closeDialogs()
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    openHomeworkAnswerDialog(item) {
      this.answerItem = {
        HomeworkId: item.HomeworkId,
        SourceTestId: item.SourceTestId
      }
      this.homeworkAnswerDialog = true;
    },

    clearFilters() {
      // this.filters = {
      //   search: '',
      //   startDate: null,
      //   endDate: null,
      //   lessonId: null,
      //   homeworkStatus: 0
      // }
      this.$router.push({name: 'odev-planlama'}).then(() => {
        this.$router.go(0)
      })
    },

    cleanSelections() {
      this.selectedHomeworks = []
      this.editedItem = {
        HomeworkIds: [],
        PlannedDate: null
      }
    },
    save() {
      this.saving = true
      this.editedItem.HomeworkIds = this.selectedHomeworks.map(x => x.HomeworkId)
      ApiService.setHeader()
      ApiService.put('api/Homework/student-homework-planing', this.editedItem)
          .then(() => {
            this.planDialog = false
            this.getData()
            this.saving = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.saving = false
          })
    },

    showDay(date) {
      return moment(date).locale('tr').format('Do MMMM dddd')
    },

    closeDialogs() {
      this.endDateDialog = false
      this.planningDateDialog = false
      this.startDateDialog = false
    },

    fixDates(dateType) {
      if (dateType == 'endDate') {
        this.filters.startDates = []
        this.filters.planningDates = []
        if (this.filters.endDates[0] && this.filters.endDates[1]) {
          var g1 = new Date(this.filters.endDates[0])
          var g2 = new Date(this.filters.endDates[1])
          if (g2.getTime() < g1.getTime()) {
            var tmpEndDate = this.filters.endDates[0]
            this.filters.endDates[0] = this.filters.endDates[1]
            this.filters.endDates[1] = tmpEndDate
          }
        }
      } else if (dateType == 'startDate') {
        this.filters.endDates = []
        this.filters.planningDates = []
        if (this.filters.startDates[0] && this.filters.startDates[1]) {
          var g3 = new Date(this.filters.startDates[0])
          var g4 = new Date(this.filters.startDates[1])
          if (g4.getTime() < g3.getTime()) {
            var tmpStartDate = this.filters.startDates[0]
            this.filters.startDates[0] = this.filters.startDates[1]
            this.filters.startDates[1] = tmpStartDate
          }
        }
      } else if (dateType == 'planningDate') {
        this.filters.endDates = []
        this.filters.startDates = []
        if (this.filters.planningDates[0] && this.filters.planningDates[1]) {
          var g5 = new Date(this.filters.planningDates[0])
          var g6 = new Date(this.filters.planningDates[1])
          if (g6.getTime() < g5.getTime()) {
            var tmpPlanningDate = this.filters.planningDates[0]
            this.filters.planningDates[0] = this.filters.planningDates[1]
            this.filters.planningDates[1] = tmpPlanningDate
          }
        }
      }

      this.getData()
    },

    getColor(item) {
      if (item.CompletedStudentCount == item.StudentCount) return 'green'
      else if (item.CompletedStudentCount > 0) return 'yellow'
      else return 'red'
    }
  }
}
</script>
